'use strict';

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

// SVG source:
// https://github.com/lipis/flag-icon-css/tree/master/flags/4x3

export default function (props) {
  return (
    <SvgIcon viewBox="0 0 810 540" {...props}>
      <defs>
        <g id="s">
          <g id="c">
            <path id="t" d="M0,0v1h0.5z" transform="translate(0,-1)rotate(18)"/>
            <use xlinkHref="#t" transform="scale(-1,1)"/>
          </g>
          <g id="a">
            <use xlinkHref="#c" transform="rotate(72)"/>
            <use xlinkHref="#c" transform="rotate(144)"/>
          </g>
          <use xlinkHref="#a" transform="scale(-1,1)"/>
        </g>
      </defs>
      <rect fill="#039" width="810" height="540"/>
      <g fill="#fc0" transform="scale(30)translate(13.5,9)">
        <use xlinkHref="#s" y="-6"/>
        <use xlinkHref="#s" y="6"/>
        <g id="l">
          <use xlinkHref="#s" x="-6"/>
          <use xlinkHref="#s" transform="rotate(150)translate(0,6)rotate(66)"/>
          <use xlinkHref="#s" transform="rotate(120)translate(0,6)rotate(24)"/>
          <use xlinkHref="#s" transform="rotate(60)translate(0,6)rotate(12)"/>
          <use xlinkHref="#s" transform="rotate(30)translate(0,6)rotate(42)"/>
        </g>
        <use xlinkHref="#l" transform="scale(-1,1)"/>
      </g>
    </SvgIcon>
  )
}
